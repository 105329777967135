import { useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { isEmpty } from 'lodash';
import { format, isValid } from 'date-fns';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add as AddIcon,
  Download,
  Edit,
  Error,
  FilterList,
  Home,
  Upload,
} from '@mui/icons-material';
import Http from '@/services/http';
import { IUser } from '@/models/user-model';
import { IPagination } from '@/models/pagination-model';
import { truncateString } from '@/helpers/truncate-string';
import { useDrawer } from '@/contexts/drawer';
// import Popover from '@/components/popover';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';
import FilterTags from '@/components/Info/filter-tags';

import UserManagmentFilterForm from './filter-form';
import UpdateUser from '../update-user';
import ImportUsers from '../import-users';
import ExportUsers from '../export-users';
import CreateUser from '../create-user';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
  }),
  '&:nth-of-type(odd), :hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const UserList: React.FC = () => {
  const drawer = useDrawer();
  const theme = useTheme();
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const truncateSize = React.useMemo(() => {
    if (isExtraLargeScreen) return 48;
    if (isLargeScreen) return 32;
    if (isMediumScreen) return 24;
    return 12;
  }, [isExtraLargeScreen, isLargeScreen, isMediumScreen]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState<IPagination<IUser>>({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const submitFilter = React.useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value._id) {
            queryValue = value._id;
          }
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      if (!values.page) {
        searchParams.delete('page');
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchUsers = useCallback(async () => {
    setLoadingUsers(true);
    const { status, data } = await Http.get('fast-tracking-users', {
      params: { ...Object.fromEntries(searchParams), limit: 50 },
    });
    setLoadingUsers(false);

    if (status === 200) {
      setUsers(data);
    }
  }, [searchParams]);

  const createUser = () => {
    drawer.open({
      element: <CreateUser />,
      onClose(newUser: IUser) {
        if (newUser) {
          fetchUsers();
        }
      },
    });
  };

  const exportUsers = () => {
    drawer.open({
      element: <ExportUsers />,
    });
  };

  const importUsers = () => {
    drawer.open({
      element: <ImportUsers />,
    });
  };

  const editUser = (user: IUser) => {
    drawer.open({
      element: <UpdateUser user={user} />,
      onClose(userUpdated: IUser) {
        if (userUpdated) {
          fetchUsers();
        }
      },
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Configurações',
          },
          {
            text: 'Usuários',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <FilterTags
              tagDefs={[
                {
                  paramKey: 'name',
                  valueGetter: (value) => `Nome ${value}`,
                },
                {
                  paramKey: 'email',
                  valueGetter: (value) => `Email ${value}`,
                },
                {
                  paramKey: 'registration',
                  valueGetter: (value) => `Registro ${value}`,
                },
                {
                  paramKey: 'role',
                  valueGetter: (value) => `Cargo ${value}`,
                },
                {
                  paramKey: 'start_createdAt',
                  valueGetter: (value) => {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Criação (De) ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'end_createdAt',
                  valueGetter: (value) => {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Criação (Até) ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'start_updatedAt',
                  valueGetter: (value) => {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Atualização (De) ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'end_updatedAt',
                  valueGetter: (value) => {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Atualização (Até) ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
              ]}
            />
            <IconButton color="secondary" {...bindTrigger(popupState)}>
              <FilterList />
            </IconButton>
            <Popover {...bindPopover(popupState)}>
              <UserManagmentFilterForm
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
              />
            </Popover>
            <IconButton color="secondary" onClick={() => createUser()}>
              <AddIcon />
            </IconButton>
            <Button
              color="secondary"
              onClick={importUsers}
              startIcon={<Upload />}
              variant="text"
            >
              Importar
            </Button>
            <Button
              color="secondary"
              onClick={exportUsers}
              startIcon={<Download />}
              variant="text"
            >
              Exportar
            </Button>
          </Stack>
        }
        title="Usuários"
      />
      <Paper sx={{ width: '100%' }}>
        <Collapse in={loadingUsers} unmountOnExit>
          <Paper
            elevation={0}
            sx={{
              alignItems: 'center',
              bgcolor: 'grey.100',
              display: 'flex',
              justifyContent: 'center',
              p: 4,
            }}
          >
            <CircularProgress color="secondary" />
          </Paper>
        </Collapse>
        <Collapse in={!loadingUsers && users.docs.length === 0}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhum usuário encontrado
            </Typography>
          </Stack>
        </Collapse>
        <Collapse in={!loadingUsers || users.docs.length > 0} unmountOnExit>
          <TableContainer sx={{ overflowX: 'auto' }}>
            <Table stickyHeader sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
              <TableBody>
                {users.docs?.map((user: IUser) => (
                  <StyledTableRow key={user._id}>
                    <TableCell sx={{ width: '1%' }}>
                      <Tooltip title="Editar">
                        <IconButton onClick={() => editUser(user)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: (
                              <Tooltip title={user.name}>
                                <span>
                                  {truncateString(user.name, truncateSize)}
                                </span>
                              </Tooltip>
                            ),
                          },
                          {
                            title: 'Email',
                            value: (
                              <Tooltip title={user.email}>
                                <span>
                                  {truncateString(user.email, truncateSize)}
                                </span>
                              </Tooltip>
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Registro',
                            value: user.registration || '-',
                          },
                          {
                            title: 'Login',
                            value: (
                              <Tooltip title={user.login}>
                                <span>
                                  {truncateString(user.login, truncateSize)}
                                </span>
                              </Tooltip>
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Atividade',
                            value: user.active ? <>ATIVO</> : <>INATIVO</>,
                          },
                          {
                            title: 'Cargo',
                            value: (
                              <Chip
                                color="info"
                                label={user.role || 'SEM CARGO DEFINIDO'}
                                size="small"
                                sx={{ width: 'fit-content' }}
                              />
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Criação',
                            value: user.createdAt
                              ? format(
                                  new Date(user.createdAt),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : '-',
                          },
                          {
                            title: 'Data de atualização',
                            value: user.updatedAt
                              ? format(
                                  new Date(user.updatedAt),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : '-',
                          },
                        ]}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        {users.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={users.totalPages}
              onChange={(_, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={users.offset}
            />
          </Box>
        )}
      </Paper>
    </Stack>
  );
};

export default UserList;
