import { FC } from 'react';

import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDrawer } from '@/contexts/drawer';

interface ICreatedUser {
  _id: string;
  login: string;
  name: string;
  email: string;
}
interface IEntryError {
  errors: string[];
  lineNo: number;
}
export interface IImportResult {
  createdUsers: ICreatedUser[];
  entriesWithErrors: IEntryError[];
}
interface IImportResultProps {
  data: IImportResult;
}

export const ImportResult: FC<IImportResultProps> = ({ data }) => {
  const drawer = useDrawer();
  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography
          sx={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 'bold' }}
          variant="h1"
        >
          Resultado da Importação
        </Typography>
        <Box flexGrow={1}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} style={{ top: -10 }}>
                      <Typography textAlign="center" variant="h6">
                        Usuários Importados
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Login</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.createdUsers.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography textAlign="center">
                          Nenhum usuário foi importado.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {data.createdUsers.map((createdUser) => (
                    <TableRow key={createdUser._id}>
                      <TableCell>{createdUser.name}</TableCell>
                      <TableCell>{createdUser.email}</TableCell>
                      <TableCell>{createdUser.login}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {data.createdUsers.length > 0 && (
              <Box px={3} py={1}>
                <Typography textAlign="right">
                  {data.createdUsers.length}{' '}
                  {data.createdUsers.length > 1 ? 'usuários' : 'usuário'}
                </Typography>
              </Box>
            )}
          </Paper>

          <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3} style={{ top: -10 }}>
                      <Typography textAlign="center" variant="h6">
                        Erros Encontrados
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Linha no arquivo</TableCell>
                    <TableCell>Mensagens de Erro</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.entriesWithErrors.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography textAlign="center">
                          Nenhum erro foi encontrado no arquivo.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {data.entriesWithErrors.map((entry, index) => (
                    <TableRow key={entry.lineNo}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>Linha {entry.lineNo}</TableCell>
                      <TableCell>{entry.errors.join(' ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {data.entriesWithErrors.length > 0 && (
              <Box px={3} py={1}>
                <Typography textAlign="right">
                  {data.entriesWithErrors.length}{' '}
                  {data.entriesWithErrors.length > 1 ? 'erros' : 'erro'}
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>

        <Stack direction="row" spacing={1}>
          <Button onClickCapture={() => drawer.closeAll()} variant="contained">
            Fechar
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
