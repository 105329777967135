import { FC, useMemo } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandCircleDown } from '@mui/icons-material';
import { rolesList } from '@/helpers/roles';

interface IColumnValueDefinition {
  value: any;
  description?: string;
}
interface IColumnDefinitionProps {
  columnIndex: number;
  description: string;
  columnType: 'number' | 'string' | 'email' | 'enum';
  enumDefinition?: (IColumnValueDefinition | string)[];
}
const columnTypeToTitleMap: Record<
  IColumnDefinitionProps['columnType'],
  string
> = {
  email: 'E-mail',
  enum: 'Escolha',
  number: 'Número',
  string: 'Texto',
};
const ColumnDefinition: FC<IColumnDefinitionProps> = ({
  columnIndex,
  description,
  columnType,
  enumDefinition,
}) => (
  <ListItem dense>
    <ListItemIcon sx={{ fontSize: '.2rem' }}>
      <Avatar sx={{ height: 30, width: 30 }}>{columnIndex}</Avatar>
    </ListItemIcon>
    <ListItemText>
      <Grid
        alignItems="flex-start"
        container
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item md={6} xs={8}>
          <Typography fontSize=".8rem" lineHeight=".2rem" variant="caption">
            Descrição
          </Typography>
          <br />
          <Typography color="primary" fontSize=".9rem" variant="caption">
            {description}
          </Typography>
        </Grid>
        <Grid item md={4} xs={4}>
          <Typography fontSize=".8rem" lineHeight=".2rem" variant="caption">
            Tipo
          </Typography>
          <br />
          <Typography color="primary" fontSize=".9rem" variant="caption">
            {columnTypeToTitleMap[columnType]}
          </Typography>
        </Grid>
        {columnType === 'enum' && (
          <Grid item xs={8}>
            <Typography fontSize=".8rem" lineHeight=".2rem" variant="caption">
              Opções
            </Typography>
            <br />
            {enumDefinition?.every((option) => typeof option === 'string') ? (
              <Typography
                color="primary"
                fontSize=".9rem"
                sx={{ mr: 1 }}
                variant="caption"
              >
                {enumDefinition.join(' | ')}
              </Typography>
            ) : (
              enumDefinition?.map((rawOption) => {
                const option = rawOption as IColumnValueDefinition;
                return (
                  <Typography
                    color="primary"
                    fontSize=".9rem"
                    sx={{ mr: 1 }}
                    variant="caption"
                  >
                    {option.value} = {option.description}
                  </Typography>
                );
              })
            )}
          </Grid>
        )}
      </Grid>
    </ListItemText>
  </ListItem>
);

const ImportContent = () => {
  const columns: IColumnDefinitionProps[] = useMemo(
    (): IColumnDefinitionProps[] => [
      {
        columnIndex: 1,
        description: 'Status do usuário',
        columnType: 'enum',
        enumDefinition: [
          {
            value: 1,
            description: 'Ativo',
          },
          {
            value: 0,
            description: 'Inativo',
          },
        ],
      },
      {
        columnIndex: 2,
        description: 'Registro do usuário',
        columnType: 'string',
      },
      {
        columnIndex: 3,
        description: 'Nome do usuário',
        columnType: 'string',
      },
      {
        columnIndex: 4,
        description: 'E-mail do usuário',
        columnType: 'email',
      },
      {
        columnIndex: 5,
        description: 'Login do usuário',
        columnType: 'string',
      },
      {
        columnIndex: 6,
        description: 'Senha do usuário',
        columnType: 'string',
      },
      {
        columnIndex: 7,
        description: 'Cargo do usuário',
        columnType: 'enum',
        enumDefinition: rolesList,
      },
    ],
    []
  );
  return (
    <Box>
      <Accordion sx={{ boxShadow: '0' }}>
        <AccordionSummary expandIcon={<ExpandCircleDown />}>
          <Typography variant="subtitle2">Requisitos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <Typography>O tamanho máximo do arquivo é de 20MB.</Typography>
            </li>
            <li>
              <Typography>
                O arquivo deve conter no mínimo uma página. Apenas a primeira
                página do arquivo será importada independentemente das demais.
              </Typography>
            </li>
            <li>
              <Typography>
                O arquivo pode conter uma linha de cabeçalho que pode ser
                ignorada marcando a opção &quot;
                <strong>Considerar a primeira linha como cabeçalho.</strong>
                &quot; no formulário abaixo.
              </Typography>
            </li>
            <li>
              <Typography>
                A planilha deve ter no mínimo {columns.length} colunas
                representando as informações dos usuários a serem importados,
                colunas adicionais serão ignoradas.
              </Typography>
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ boxShadow: '0' }}>
        <AccordionSummary expandIcon={<ExpandCircleDown />}>
          <Typography variant="subtitle2">Definição de Colunas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {columns.map((col, index) => {
              const isLast = index === columns.length - 1;
              return (
                <>
                  <ColumnDefinition {...col} key={col.columnIndex} />
                  {!isLast && <Divider />}
                </>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export const ImportUsersGuide = () => <ImportContent />;
