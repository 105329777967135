import { FormContainer, SwitchElement } from 'react-hook-form-mui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Attachment, Close } from '@mui/icons-material';
import Http from '@/services/http';
import { formatBytes } from '@/helpers/format-bytes';
import { useDrawer, useItemDrawer } from '@/contexts/drawer';

import { IImportResult, ImportResult } from './components/import-result';
import { ImportUsersGuide } from './components/import-guide';

const MAX_IMPORT_FILE_SIZE = 20 * 1000 * 1000; // 20 MB

type Form = {
  skipHeaderRowInXLSXFiles: boolean;
  file: File | null;
};

const ImportUsers: React.FC = () => {
  const form = useForm<Form>({
    defaultValues: {
      skipHeaderRowInXLSXFiles: true,
      file: null,
    },
  });

  const drawerItem = useItemDrawer();
  const drawer = useDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File | null>(null);

  const importUsersSubmit: SubmitHandler<Form> = useCallback(
    async (values) => {
      if (!values.file) return;

      const formData = new FormData();
      formData.append('file', values.file);

      const { data, status } = await Http.post(
        '/fast-tracking-users/import-from-file',
        formData,
        {
          params: {
            skipHeaderRowInXLSXFiles: values.skipHeaderRowInXLSXFiles
              ? 'true'
              : 'false',
          },
        }
      );

      if (status === 201) {
        enqueueSnackbar('Usuários importados com sucesso!');
        drawer.open({
          element: <ImportResult data={data as IImportResult} />,
        });
      } else {
        enqueueSnackbar(
          data?.params?.details ??
            data?.message ??
            'Erro desconhecido ao importar usuários.',
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar, drawer]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileLoaded = event?.target?.files && event.target.files[0];

    if (!fileLoaded) {
      return;
    }

    if (fileLoaded.size > MAX_IMPORT_FILE_SIZE) {
      enqueueSnackbar('Este arquivo ultrapassa o limite de upload de 20MB.', {
        variant: 'error',
      });
      return;
    }

    form.setValue('file', fileLoaded);
    setFile(fileLoaded);
  };

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography
          sx={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 'bold' }}
          variant="h1"
        >
          Importar usuários
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
            autoComplete: 'false',
          }}
          onSuccess={importUsersSubmit}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <ImportUsersGuide />
              </Grid>
              <Grid item xs={12}>
                <SwitchElement
                  label="Considerar a primeira linha como cabeçalho."
                  name="skipHeaderRowInXLSXFiles"
                />
              </Grid>
              <Grid
                alignItems="center"
                container
                flexDirection="column"
                item
                justifyContent="center"
                sm={12}
              >
                <ButtonGroup>
                  <Button
                    color="secondary"
                    component="label"
                    startIcon={<Attachment />}
                    variant="outlined"
                  >
                    {!file
                      ? 'Selecionar Arquivo'
                      : `${file.name} ${formatBytes(file.size)}`}
                    <input
                      accept=".xlsx"
                      hidden
                      name="file"
                      onChange={(e) => handleFileChange(e)}
                      type="file"
                    />
                  </Button>
                  {file !== null && (
                    <Button onClick={() => setFile(null)}>
                      <Close color="secondary" />
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              disabled={file === null || form.formState.isSubmitting}
              startIcon={
                <Collapse
                  component="div"
                  in={form.formState.isSubmitting}
                  orientation="horizontal"
                  sx={{
                    maxHeight: 24,
                  }}
                >
                  <CircularProgress
                    color="secondary"
                    size={16}
                    sx={{ mb: 0.6 }}
                  />
                </Collapse>
              }
              type="submit"
              variant="contained"
            >
              Importar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default ImportUsers;
